import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Section } from '../Layout'
import CaseStudySlider from './CaseStudySlider'

const CaseStudiesCardsSlice = () => {
  const data = useStaticQuery(graphql`
    query CaseStudies {
      allContentfulCaseStudy {
        nodes {
          heroImage {
            gatsbyImageData(placeholder: NONE)
          }
          shortDescription
          title
          slug
        }
      }
    }
  `)
  const caseStudies = data.allContentfulCaseStudy.nodes
  return (
    <Section bgStyle="dark">
      <CaseStudySlider caseStudies={caseStudies}/>
    </Section>
  )
}

export default CaseStudiesCardsSlice
