import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Section } from '../Layout'
import LongTitle from '../Shared/FontStyles'

const StyledHeader = styled.div`
  max-width: 800px;
  ${media.lg`
    justify-content: space-between;
  `}
`

const StyledSubtitle = styled.p`
  max-width: 600px;
  text-align: left;
  margin-top: 20px;
`

const PageTitle = styled.p`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-top: 40px;
  margin-bottom: 40px;
`

const CaseStudiesHeaderSlice = () => {
  const sliceContent = {
    title: 'We are proud to work with leading industry champions worldwide',
    subTitle:
      "Discover global success stories and learn how Semplates empowers customers across industries with its advanced AWS SES template manager, driving efficiency and team collaboration.",
    pageTitle: 'Case Studies',
  }
  return (
    <Section>
      <StyledHeader>
        <PageTitle>{sliceContent.pageTitle}</PageTitle>
        <LongTitle>{sliceContent.title}</LongTitle>
        <StyledSubtitle>{sliceContent.subTitle}</StyledSubtitle>
      </StyledHeader>
    </Section>
  )
}

export default CaseStudiesHeaderSlice
