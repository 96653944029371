import * as React from 'react'
import Seo from '../components/Layout/Seo'
import Layout from '../components/Layout/Layout'
import CaseStudiesHeaderSlice from '../components/CaseStudies/CaseStudiesHeaderSlice'
import CaseStudiesCardsSlice from '../components/CaseStudies/CaseStudiesCardsSlice'

const CaseStudiesPage = () => {
  const pageName = 'Case Studies'
  const breadcrumbList = [{ position: 1, name: pageName, path: 'case-studies' }]

  return (
    <Layout>
      <Seo
        title="Case Studies"
        pathname="case-studies"
        breadcrumbList={breadcrumbList}
      />
      <CaseStudiesHeaderSlice />
      <CaseStudiesCardsSlice />
    </Layout>
  )
}

export default CaseStudiesPage
